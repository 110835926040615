import { createRouter, createWebHistory } from 'vue-router'
import StartView from '../views/StartView.vue'
import ProgrammView from '../views/ProgrammView.vue'
import ThemaView from '../views/ThemaView.vue'
import LandkreiseView from '../views/LandkreiseView.vue'
import KandidatenView from '../views/KandidatenView.vue'
import MitmachenView from '../views/MitmachenView.vue'
import LinkView from '../views/LinkView.vue'
import GotvView from '../views/GotvView.vue'
import PartyView from '../views/PartyView.vue'

const routes = [
  {
    path: '/',
    name: 'Startseite',
    component: StartView
  },
  {
    path: '/Ideen',
    name: 'Programm',
    component: ProgrammView
  },
  {
    path: '/Ideen/:thema',
    name: 'Thema',
    component: ThemaView
  },
  {
    path: '/Kandidaten',
    name: 'Landkreise',
    component: LandkreiseView
  },
  {
    path: '/Kandidaten/:slug',
    name: 'Kandidaten',
    component: KandidatenView
  },
  {
    path: '/Mitmachen',
    name: 'Mitmachen',
    component: MitmachenView
  },
  {
    path: '/Kontakt',
    name: 'Kontakt',
    component: MitmachenView
  },
  {
    path: '/Insta',
    name: 'Links',
    component: LinkView
  },
  {
    path: '/GOTV',
    name: 'Get Out The Vote',
    component: GotvView
  },
  {
    path: '/Party',
    name: 'Young, Vote & Free',
    component: PartyView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
