<template>
  <div class="termin">
    <a v-if="kandidat" :href="'/Kandidaten/' + kandidat"><img class="kandidatFoto" :class="{schatten: kandidat === null}" :src="kandidatPicture" alt="Kandidat" /></a>
    <img v-else class="kandidatFoto" :class="{schatten: kandidat === null}" :src="kandidatPicture" alt="Kandidat" />
    <Heading class="title" :text="title" :smallMargin="true" />
    <p class="daten">
      <span style="max-width: calc(100% - 6em); display: inline-block;">
        <svg class="dateSVG"  viewBox="0 0 22.5729 22.1329">
          <defs>
            <mask id="hole">
              <path d="m1.8597,22.115h18.8535c1.01302,0 1.84175-.81385 1.84175-1.80869v-15.1689c0-.99484-.82873-1.80869-1.84175-1.80869h-1.26627v2.11916c0,.44474-.36388.80862-.80862.80862h-3.07934c-.44474,0-.80862-.36388-.80862-.80862v-2.11916h-7.0021v2.11939c0,.44474-.36388.80862-.80862.80862h-3.07934c-.44474,0-.80862-.36388-.80862-.80862v-2.11939h-1.19195c-1.01302,0-1.84175.81385-1.84175,1.80869v15.1689c0,.99484.82873,1.80869 1.84175,1.80869z" fill="#fff" />
              <path d="m2.8789 20.8h16.81c0.93107 0 1.6928-0.57388 1.6928-1.2754v-11.64h-20.195v11.64c0 0.70152 0.76171 1.2754 1.6928 1.2754z" fill="#000" />
            </mask>
          </defs>
          <path mask="url(#hole)" d="m1.8597,22.115h18.8535c1.01302,0 1.84175-.81385 1.84175-1.80869v-15.1689c0-.99484-.82873-1.80869-1.84175-1.80869h-1.26627v2.11916c0,.44474-.36388.80862-.80862.80862h-3.07934c-.44474,0-.80862-.36388-.80862-.80862v-2.11916h-7.0021v2.11939c0,.44474-.36388.80862-.80862.80862h-3.07934c-.44474,0-.80862-.36388-.80862-.80862v-2.11939h-1.19195c-1.01302,0-1.84175.81385-1.84175,1.80869v15.1689c0,.99484.82873,1.80869 1.84175,1.80869z" />
          <path d="m5.1273 0.0471h0.52814a1.203 1.203 0 0 1 1.203 1.203v3.1689a1.203 1.203 0 0 1 -1.203 1.203h-0.52814a1.203 1.203 0 0 1 -1.203 -1.203v-3.1689a1.203 1.203 0 0 1 1.203 -1.203" />
          <path d="m16.825 0.04714h0.52814a1.203 1.203 0 0 1 1.203 1.203v3.1689a1.203 1.203 0 0 1 -1.203 1.203h-0.52814a1.203 1.203 0 0 1 -1.203 -1.203v-3.1689a1.203 1.203 0 0 1 1.203 -1.203" />
        </svg>&nbsp;{{convertTime}}
      </span>
      <span v-if="location.length < 30" id="datenStrich">&nbsp;|&nbsp;</span>
      <br v-else />
      <svg class="locationSVG" viewBox="115 140 1080 1550">
        <path d="m653.54 143.43c-292.64 0-529.87 237.23-529.87 529.87 0 116.88 37.87 224.89 101.97 312.51l427.9 690.23 427.9-690.23c64.099-87.617 101.97-195.63 101.97-312.51-1e-3 -292.64-237.23-529.87-529.87-529.87zm0 753.42c-146.5 0-265.26-118.76-265.26-265.26s118.76-265.26 265.26-265.26 265.26 118.76 265.26 265.26-118.76 265.26-265.26 265.26z"/>
      </svg>&nbsp;{{location}}
    </p>
    <p class="beschreibung" v-html="text ? text.replaceAll('\n', '<br>') : ''" />
  </div>
</template>

<script>
import Heading from '../components/Heading'

export default {
  name: 'TerminComponent',

  components: {
    Heading
  },

  props: {
    title: { type: String, default: '' },
    von: { type: Date, default: null },
    bis: { type: Date, default: null },
    location: { type: String, default: '' },
    text: { type: String, default: '' },
    kandidat: { type: String, default: '' }
  },

  computed: {
    kandidatPicture: function () {
      if (this.kandidat === null) return require('@/assets/images/Logo_JuliA_SN_klein.svg')
      return require('@/assets/images/Kandidaten/' + this.kandidat + '.webp')
    },

    convertTime: function () {
      const von = this.von.toLocaleDateString('de-DE') + (this.von.getHours() + this.von.getMinutes() > 0 ? ' ' + this.von.getHours() + ':' + (this.von.getMinutes() < 10 ? '0' : '') + this.von.getMinutes() : '')
      if (this.bis.getTime() === 0) return von
      if (this.bis.getDate() > this.von.getDate() || this.bis.getMonth() > this.von.getMonth() || this.bis.getYear() > this.von.getYear()) {
        const bis = this.bis.toLocaleDateString('de-DE') + (this.von.getHours() + this.von.getMinutes() > 0 ? ' ' + this.bis.getHours() + ':' + (this.bis.getMinutes() < 10 ? '0' : '') + this.bis.getMinutes() : '')
        return von + ' - ' + bis
      } else {
        if (this.von.getHours() + this.von.getMinutes() === 0) return von
        const bis = this.bis.getHours() + ':' + (this.bis.getMinutes() < 10 ? '0' : '') + this.bis.getMinutes()
        return von + ' - ' + bis
      }
    }
  }
}
</script>

<style scoped>
.termin {
  margin-bottom: 0.5em;
  display: inline-block;
  position: relative;
}
@media (min-aspect-ratio: 3/2) {
  .termin {
    width: calc(50% - 1em - 0.1px);
    float: left;
  }

  .termin:nth-of-type(2n+1) {
    margin-right: 0.5em;
  }

  .termin:nth-of-type(2n) {
    margin-left: 0.5em;
  }
}
@media (max-aspect-ratio: 3/2) {
  .termin {
    width: 100%;
  }
}

.title {
  font-size: 0.5em;
  hyphens: auto;
  width: calc(100% - 10em);
}

.dateSVG, .locationSVG {
  fill: var(--magenta);
  filter: drop-shadow(-2px -2px var(--darkMagenta));
  height: 1em;
  margin: 0.1em;
}

.daten {
  margin: 0;
  width: 100%;
  text-align: left;
}

.beschreibung {
  margin: 0.5em 0 0 0;
}

.kandidatFoto {
  width: 4.5em;
  height: 4.5em;
  object-fit: contain;
  object-position: right top;
  float: right;
  position: absolute;
  right: 0;
  margin-top: -0.5em;
}

.schatten {
  filter: drop-shadow(-3px -3px var(--darkMagenta));
}

@media (orientation:portrait) {
  #datenStrich {
    display: block;
    height: 0.25em;
    color: transparent;
    overflow: hidden;
  }

  .kandidatFoto {
    width: 6em;
    height: 6em;
  }
}
</style>
