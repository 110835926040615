<template>
  <div class="heading" :class="{ alignRight: alignRight }">
    <h1 class="framed" :class="{ colorsInverted: colorsInverted }" :style="styleHeading"><div v-html="text" /><span><div v-html="text" /></span></h1>
  </div>
</template>

<script>
export default {
  name: 'HeadingComponent',

  props: {
    text: { type: String, default: '' },
    angle: { type: Number, default: 5 },
    alignRight: { type: Boolean, default: false },
    colorsInverted: { type: Boolean, default: false },
    smallMargin: { type: Boolean, default: false }
  },

  computed: {
    styleHeading: function () {
      if (this.alignRight) {
        return 'transform: rotate(-' + this.angle + 'deg);' +
               'margin: calc(max(60vw, 12em) / 2 / sin(90 / 180 * pi) * sin(' + this.angle + ' / 180 * pi));' +
               'margin-right: 0.4em;' +
               'margin-left: 0em;'
      } else {
        if (this.smallMargin) {
          return 'transform: rotate(-' + this.angle + 'deg);' +
                 'margin: 0;' +
                 'margin-top: calc(20em / 4 / sin(90 / 180 * pi) * sin(' + this.angle + ' / 180 * pi));' +
                 'margin-bottom: calc(20em / 2 / sin(90 / 180 * pi) * sin(' + this.angle + ' / 180 * pi));'
        } else {
          return 'transform: rotate(-' + this.angle + 'deg);' +
                 'margin: calc(max(60vw, 12em) / 2 / sin(90 / 180 * pi) * sin(' + this.angle + ' / 180 * pi));' +
                 'margin-right: 0em;'
        }
      }
    }
  }
}
</script>

<style scoped>
.heading {
  display: block;
  user-select: none;
  position: relative;
  z-index: 10;
}

.alignRight {
  text-align: right;
}

h1.framed {
  line-height: 1;
  font-size: 2.2em;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--magenta);
  position: relative;
  font-family: Anybody ExtraBold;
  display: inline-block;
  max-width: max(100vw - 12em, 12em);
  user-select: none;
}

h1.framed span {
  -webkit-text-stroke: 0.4em var(--yellow);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  user-select: none;
}

h1.framed.colorsInverted {
  color: var(--yellow);
}
h1.framed.colorsInverted span {
  -webkit-text-stroke: 0.4em var(--magenta);
}
</style>
