<template>
  <div id="start">
    <a href="https://home.julia-sachsen.de" style="position: relative; z-index: 11; float: right; margin-top: 2vw; text-align: right; font-weight: bold; margin-right: 2em; hyphens: none; color: var(--black); text-decoration: none;">zur regulären<br>Website&nbsp;→</a>
    <Heading text="2024. Land&shy;tachs&shy;wohl. Un nu?" />
    <div id="beschreibung">
      <img id="FotoSlider" :src="sliderImage" v-on:click="imageId++" alt="Eindrücke JuliA" />
      Als Jungliberale Aktion Sachsen setzen wir uns für eine freiheitsliebende und <b>zukunftsfähige Politik</b> in unserem <b>Freistaat</b> ein.<br>
      Zur Landtagswahl am <b>1. September</b> treten wir mit vielen jungen <router-link to="/Kandidaten">Kandidaten</router-link> gemeinsam mit der FDP an.
      Mit unserem <b>Spitzenkandidaten Hai</b> tragen wir unsere <router-link to="/Ideen">Ideen</router-link> für Sachsen in den Landtag!<br>
      Du begeisterst dich auch für liberale Themen? Dann <router-link to="/Mitmachen">mach mit</router-link> bei uns!
      <a href="https://home.julia-sachsen.de" target="_blank"><img id="logo" src="@/assets/images/Logo_JuliA_SN_hell.svg" alt="Logo JuliA" /></a>
    </div>
    <span v-if="termine.length > 0">
      <Heading text="Termine" colorsInverted="true" />
      <p>Hier könnt ihr uns treffen:</p>
      <div id="termine">
        <div v-for="i in Math.ceil(termine.length / 2)" :key="i" style="display: inline-block;">
          <Termin v-for="termin in termine.slice(2*i - 2, 2*i)" :key="termin" :title="termin.Name" :von="new Date(termin.Datum)" :bis="new Date(termin.Ende)" :location="termin.Ort" :text="termin.Text" :kandidat="termin.Kandidat" />
        </div>
      </div>
    </span>
    <img class="streifen" src="@/assets/images/Orte/wermsdorf.webp" alt="Sachsen" />
  </div>
</template>

<script>
import Heading from '../components/Heading'
import Termin from '../components/Termin'

import axios from 'axios'

export default {
  name: 'StartView',

  components: {
    Heading,
    Termin
  },

  data () {
    return {
      imageId: 0,
      images: [
        require('@/assets/images/Slider/01.webp'),
        require('@/assets/images/Slider/04.webp'),
        require('@/assets/images/Slider/05.webp'),
        require('@/assets/images/Slider/02.webp'),
        require('@/assets/images/Slider/06.webp'),
        require('@/assets/images/Slider/03.webp')
      ],
      sliderIntervall: null,
      termine: []
    }
  },

  methods: {

  },

  computed: {
    sliderImage: function () {
      return this.images[this.imageId % this.images.length]
    }
  },

  mounted: function () {
    window.scrollTo(0, 0)
    this.sliderIntervall = setInterval(() => {
      document.getElementById('FotoSlider').classList.add('invisible')
      setTimeout(() => {
        this.imageId = (this.imageId + 1) % this.images.length
        document.getElementById('FotoSlider').classList.remove('invisible')
      }, 200)
    }, 5000)

    axios.get('https://sachsenupgrade.de/termin_records.php', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      this.termine = response.data
      console.log(this.termine)
    })
  },

  beforeUpdate: function () {
    clearInterval(this.sliderIntervall)
  },

  updated: function () {
    this.sliderIntervall = setInterval(() => {
      document.getElementById('FotoSlider').classList.add('invisible')
      setTimeout(() => {
        this.imageId = (this.imageId + 1) % this.images.length
        document.getElementById('FotoSlider').classList.remove('invisible')
      }, 200)
    }, 5000)
  },

  beforeUnmount: function () {
    clearInterval(this.sliderIntervall)
  }
}
</script>

<style scoped>
#FotoSlider {
  max-height: 80vh;
  object-fit: cover;
  opacity: 1;
  position: relative;
  z-index: 0;
  transition: 0.2s ease-out;
}
#FotoSlider.invisible {
  opacity: 0.1;
}
#beschreibung {
  padding: 0 2em;
  overflow: hidden;
  display: block;
  text-align: justify;
  hyphens: auto;
  margin-top: -2em;
  padding-top: 2em;
}

#beschreibung br {
  display: block;
  margin-bottom: 0.5em;
}
@media (orientation:portrait) {
  #FotoSlider {
    width: calc(100% + 3em);
    margin-left: -1.5em;
    margin-top: -2em;
    margin-bottom: 0.5em;
    border-radius: 1em;
    aspect-ratio: 3/2;
  }
}
@media (orientation:landscape) {
  #FotoSlider {
    margin-top: -1em;
    margin-right: -2em;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
    float: right;
    width: max(calc(100% - 14em), calc(50vw - 3vw));
    min-height: 70vh;
    border-radius: 1em 0 0 1em;
  }
}

#logo {
  width: 300px;
  max-width: calc(100% - 4em - 20%);
  display: block;
  margin: 0.5em auto;
}

#termine {
  width: calc(100% - 4em);
  padding: 0 2em;
}
</style>
