<template>
  <div id="programm">
    <Heading text="Unsere Ideen für Sachsen"/>
    <img id="FotoSlider" :src="sliderImage" v-on:click="imageId++" alt="" />
    <p>2024. Landtachswohl. Un nu?</p>
    <p>Wenn die politischen Extreme immer stärker werden, braucht es umso mehr eine <b>demokratische und freiheitliche Kraft</b> im Sächsischen Landtag, die auf dem Boden unserer Verfassung steht und eine <b>vernunftorientierte Politik</b> betreibt. Wir Jungliberalen wollen diese zusammen mit der FDP sein und dies ist bitter notwendig. Die aktuelle sächsische Staatsregierung regiert den Freistaat unter seinem Wert. Sie fesselt lieber die sächsische Bevölkerung und ihr Potential, als die Rahmenbedingung für ein <b>selbstbestimmtes und erfolgreiches Leben</b> zu schaffen. Wir wollen eine Regierung, die die <b>jungen Menschen</b> nicht vergisst, sondern mitdenkt. Eine Regierung, die ihnen die Werkzeuge für ein freies Leben an die Hand gibt und ihnen nicht nur Hindernisse in den Weg legt.</p>
    <p>Es bedarf einer Politik, welche die Zukunft für junge Menschen <b>aktiv gestalten</b> und nicht nur das Gegebene verwalten will. Genau diesen Gestaltungsanspruch besitzen wir. So stehen wir für eine <b>chancengerechte Bildungs- und Ausbildungspolitik</b>, die Antworten findet, welche über die reinen finanziellen Aspekte hinausgehen. Wir wollen ein <b>freiheitliches Sachsen</b>, in dem Sicherheit besteht, ohne dass dafür deine Freiheit geopfert wird! Für uns ist die Freiheit des Einzelnen Grund und Grenze jeglichen politischen Handelns. Der Mittelstand Sachsens muss <b>entfesselt</b> und gestärkt werden, damit dem Land eine starke Zukunft bevorsteht.</p>
    <p>Das folgende Programm soll einen Ausblick darauf geben, wie wir unsere Heimat <b>voranbringen</b> wollen. Ganz im Sinne des Einsatzes für deine Zukunft in Sachsen wollen wir zeigen, welches Potential in unserem Freistaat steckt.</p>
    <div class="slogan white-text portrait">Sachsen<br>UPGRADE</div>
    <div id="slider">
      <div class="themenBlock fade" v-for="(thema, key) in Kapitel" :key="thema">
        <div class="slogan yellow-text">
          <a class="prevButton" v-on:click="plusSlides(-1)">&#10094;</a>
          <span class="white-text landscape">SachsenUPGRADE</span><span v-html="thema.titel" />
          <a class="nextButton" v-on:click="plusSlides(1)">&#10095;</a>
        </div>
        <ul class="shortText">
          <li v-for="punkt in thema.kurzform" :key="punkt" v-html="punkt" />
        </ul>
        <router-link class="forderungenButton" :to="'/Ideen/' + key">alle Forderungen</router-link>
      </div>
    </div>
    <img class="streifen" src="@/assets/images/Orte/bagger.webp" alt="Sachsen" />
  </div>
</template>

<script>
import Heading from '../components/Heading'

import Programm from '../scripts/Programm'

export default {
  name: 'ProgrammView',

  mixins: [Programm],

  components: {
    Heading
  },

  data () {
    return {
      slideIndex: 0,
      imageId: 0,
      images: [
        require('@/assets/images/Programm/01.webp'),
        require('@/assets/images/Programm/02.webp'),
        require('@/assets/images/Programm/03.webp'),
        require('@/assets/images/Programm/04.webp'),
        require('@/assets/images/Programm/05.webp'),
        require('@/assets/images/Programm/06.webp')
      ],
      sliderIntervall: null
    }
  },

  methods: {
    plusSlides: function (n) {
      this.slideIndex = (this.slideIndex + n + Object.keys(this.Kapitel).length) % Object.keys(this.Kapitel).length
      this.showSlides()
    },

    showSlides: function () {
      const slides = document.getElementsByClassName('themenBlock')
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none'
      }
      slides[this.slideIndex].style.display = 'block'
    }
  },

  computed: {
    sliderImage: function () {
      return this.images[this.imageId % this.images.length]
    }
  },

  mounted: function () {
    window.scrollTo(0, 0)
    this.showSlides()
    this.sliderIntervall = setInterval(() => {
      document.getElementById('FotoSlider').classList.add('invisible')
      setTimeout(() => {
        this.imageId = (this.imageId + 1) % this.images.length
        document.getElementById('FotoSlider').classList.remove('invisible')
      }, 200)
    }, 4000)
  },

  beforeUpdate: function () {
    clearInterval(this.sliderIntervall)
  },

  updated: function () {
    this.sliderIntervall = setInterval(() => {
      document.getElementById('FotoSlider').classList.add('invisible')
      setTimeout(() => {
        this.imageId = (this.imageId + 1) % this.images.length
        document.getElementById('FotoSlider').classList.remove('invisible')
      }, 200)
    }, 4000)
  },

  beforeUnmount: function () {
    clearInterval(this.sliderIntervall)
  }
}
</script>

<style scoped>
.slogan {
  font-size: 1.5em;
  text-align: center;
  background: var(--magenta);
  padding: 0 2em;
  hyphens: auto;
}

.forderungenButton {
  margin: 1em 2em;
  color: var(--magenta);
  font-family: Anybody;
  cursor: pointer;
  background: var(--yellow);
  padding: 0.3em;
  border-radius: 0.15em;
  box-shadow: -4px -4px var(--darkYellow);
  display: inline-block;
}

ul {
  padding: 0 0 0 1em;
}

p {
  margin-bottom: 0.5em;
}

@media (orientation:portrait) {
  .landscape {
    display: none;
  }
}

@media (orientation:landscape) {
  .portrait {
    display: none;
  }
}

.slider {
  width: 100%;
  position: relative;
  margin: auto;
}

.themenBlock {
  display: none;
}

.shortText {
  margin-top: 0.5em;
}

.prevButton, .nextButton {
  cursor: pointer;
  position: absolute;
  width: 2em;
  color: var(--white);
  transition: 0.6s ease;
  user-select: none;
  margin: 0 3vw;
  text-decoration: none;
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
}

.prevButton:hover, .nextButton:hover, .prevButton:focus, .nextButton:focus {
  background: var(--yellow);
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}

#FotoSlider {
  max-height: 65vh;
  object-fit: cover;
  position: relative;
  z-index: 0;
  opacity: 1;
  transition: 0.2s ease-out;
  aspect-ratio: 3/2;
}
#FotoSlider.invisible {
  opacity: 0.1;
}
@media (orientation:portrait) {
  #FotoSlider {
    width: calc(100% - 1em);
    margin-left: 0.5em;
    object-fit: contain;
    margin-top: -1em;
    margin-bottom: 0.5em;
    border-radius: 1em;
    object-fit: cover;
  }
}
@media (orientation:landscape) {
  #FotoSlider {
    margin-top: -1em;
    margin-right: 0;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
    float: right;
    width: calc(50vw - 3vw);
    border-radius: 1em 0 0 1em;
  }
}
</style>
