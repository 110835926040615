<template>
  <div id="programm">
    <Heading text="Unsere Ideen"/>
    <span v-for="teil in Kapitel[$route.params.thema].teile" :key="teil.titel">
      <div class="slogan white-text portrait">Sachsen<br>UPGRADE</div>
      <div class="slogan yellow-text">
        <span class="white-text landscape">SachsenUPGRADE</span><span v-html="teil.titel" />
      </div>
      <div class="programmText" v-html="teil.gesamt" />
    </span>
    <img class="streifen" src="@/assets/images/Orte/bagger.webp" alt="Sachsen" />
  </div>
</template>

<script>
import Heading from '../components/Heading'

import Programm from '../scripts/Programm'

export default {
  name: 'ThemaView',

  mixins: [Programm],

  components: {
    Heading
  },

  data () {
    return {

    }
  },

  methods: {

  },

  mounted: function () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.slogan {
  font-size: 1.5em;
  text-align: center;
  background: var(--magenta);
  padding: 0 2em;
  hyphens: auto;
}

@media (orientation:portrait) {
  .landscape {
    display: none;
  }
}

@media (orientation:landscape) {
  .portrait {
    display: none;
  }
}
</style>
<style>
.programmText {
  margin-top: 1em;
}

.programmText h2 {
  margin: 1em 2em 0 2em;
  font-size: 1em;
  font-family: Anybody ExtraBold;
  color: var(--magenta);
}

.programmText li {
  margin: 0.5em 0 0.5em 0;
}

.programmText ul {
  list-style-type: '… ';
  padding: 0 0 0 1em;
}
</style>
