export default {
  data () {
    return {
      Kandidaten: [
        {
          titel: 'Dr.',
          vorname: 'Lino',
          nachname: 'Markfort',
          wahlkreis: 'Vogtland 1',
          landkreis: 'Vogtland',
          biografie: [
            'Aktuell tätig als selbständiger Berater für die Immobilienwirtschaft',
            'Studium der Betriebswirtschaftslehre mit abgeschlossener Promotion an den Universitäten Bayreuth und Leipzig',
            'seit über 20 Jahren passionierter Hobbymusiker (Gesang, Schlagzeug & Gitarre)'
          ],
          themen: [
            '<b>Wirtschaft</b> Ich strebe den Hochlauf zukunftsträchtiger Wirtschaftsbereiche an und setze mich für die Stärkung der Gründungskultur sowie für eine Entbürokratisierung ein.',
            '<b>Bildung & Forschung</b> Mein Ziel ist es, das Netzwerk und das Angebot weiterführender Bildungseinrichtungen im ländlichen Bereich auszubauen. Ich möchte die Forschungsprofile stärken und die Digitalisierung in Forschung und Lehre vorantreiben.',
            '<b>Kultur</b> Ich setze mich für die Schärfung und Stärkung der Kulturlandschaft ein und möchte länderübergreifende Kooperationen fördern.'
          ],
          zitat: 'KMUs sind mit meinem Anteil von 99,8% das Rückgrat der sächsischen Wirtschaft. Ein Mittelstand mit Zukunft braucht eine schlanke Bürokratie, eine gute digitale Infrastruktur und steuerliche Anreize. Dafür kämpfe ich!',
          some: {
            instagram: 'linomarkfort_fdp'
          },
          picture: require('@/assets/images/Kandidaten/Lino.webp')
        }, {
          vorname: 'Luisa',
          nachname: 'Strobel',
          wahlkreis: 'Vogtland 3',
          landkreis: 'Vogtland',
          biografie: [
            '24 Jahre alt, aus Reichenbach im Vogtland',
            'Studium Allgemeine Verwaltung',
            'Beruf: danach Kommunale Integrationskoordinatorin',
            'Stellvertretende Kreisvorsitzende FDP Vogtland und Vorsitzende JuliA Vogtland',
            'Sportschützin, Motorradfahrerin'
          ],
          themen: [
            'Integration und Migration',
            'Chancengerechtigkeit',
            'Regionalentwicklung'

          ],
          zitat: 'Ich möchte, dass sich auch in Zukunft viele junge Vogtländer eine Zukunft hier vor Ort aufbauen können.',
          some: {
            instagram: 'luisa.strobel',
            linkedin: 'luisa-strobel-517b4129a',
            facebook: 'p/Luisa-Strobel-100017193789452'
          },
          picture: require('@/assets/images/Kandidaten/Luisa.webp')
        }, {
          vorname: 'Raphael',
          nachname: 'Roch',
          wahlkreis: 'Zwickau 1',
          landkreis: 'Zwickau',
          biografie: [
            'Ausbildung zum Industriemechaniker in Zwickau',
            'Studium zum Maschinenbauingenieur in Zwickau',
            'Seit 2017 Projektingenieur im Fahrzeugbau'
          ],
          themen: [
            'Sicherstellung einer guten Bildung (von Kita bis Hochschule)',
            'Weniger sinnlose Ausgaben für den Staat, sondern Maßnahmen für den Bürger',
            'Wiederherstellung einer funktionierenden Infrastruktur',
            'Weniger Staat mehr Freiheit'
          ],
          zitat: 'Mehrere tausend neue Stellen können wir uns als Sachsen in Zeiten von digitalen und automatisierbaren Prozessen einfach nicht leisten. Es ist Zeit hier mit Vernunft aufzuräumen!',
          some: {
            instagram: 'roch_raphael',
            threads: 'roch_raphael'
          },
          picture: require('@/assets/images/Kandidaten/Raphael.webp')
        }, {
          vorname: 'Hai',
          nachname: 'Bui',
          wahlkreis: 'Chemnitz 1',
          landkreis: 'Chemnitz',
          biografie: [
            '31 Jahre alt',
            'Wirtschaftswissenschaftler',
            'Rugby-Spieler'
          ],
          themen: [
            'Generationsgerechte Finanzen',
            'Stärkung der Bürgerrechte',
            'Erneuerung des liberalen Aufstiegsversprechens'
          ],
          zitat: 'Kein Kind darf die Schule ohne qualifizierten Abschluss verlassen. Gute Bildung ist der Garant für sozialen und wirtschaftlichen Aufstieg.',
          some: {
            instagram: 'hai.happen_'
          },
          picture: require('@/assets/images/Kandidaten/Hai.webp')
        }, {
          vorname: 'Marvin',
          nachname: 'Seidel',
          wahlkreis: 'Erzgebirge 1',
          landkreis: 'Erzgebirge',
          biografie: [
            'Softwareentwickler (.net/CRM)',
            'nebenberufliches Studium: IT Management',
            'nebenberuflich: Club-DJ, Barkeeper und Eventtechniker',
            'Drohnenpilot und Hobbyfussballer'
          ],
          themen: [
            '<b>Verkehr</b> Freie Wahl des Verkehrsmittels, Individualverkehr schützen',
            '<b>Digitalisierung</b> Ausbau der Digitalen Infrastruktur, IT-Talente und Unternehmen bei uns behalten, IT-Arbeitsmarkt attraktiver gestalten',
            '<b>Nachtleben und Gastronomie</b> Clubs und Restaurants erhalten, weniger Einschränkungen für Open-Air Veranstaltungen und keine Tanzverbote'
          ],
          zitat: '',
          some: {
            instagram: 'marvin.seidel'
          },
          picture: require('@/assets/images/Kandidaten/Marvin.webp')
        }, {
          vorname: 'Tim',
          nachname: 'Suttner',
          wahlkreis: 'Mittelsachsen 1',
          landkreis: 'Mittelsachsen',
          biografie: [
            'Azubi zum Bankkaufmann',
            'Vereinsmensch seit klein an',
            'Nebenjob in der Gastronomie'
          ],
          themen: [
            'Vereine stärken',
            'Ausbildung attraktiver machen',
            'Verbesserung der Bedingungen für die Gastronomie und des Nachtlebens'
          ],
          zitat: 'Vereine sind das Rückgrat unserer Gesellschaft und prägen unser Sozialleben. Sie bieten Raum für kulturellen Austausch, sportliche Aktivitäten, Spaß sowie gemeinnützige oder wohltätige Initiativen. Deswegen verdienen sie eine stärkere Berücksichtigung im politischen Fokus.',
          picture: require('@/assets/images/Kandidaten/Tim.webp')
        }, {
          vorname: 'Marko',
          nachname: 'Trautmann',
          wahlkreis: 'Mittelsachsen 2',
          landkreis: 'Mittelsachsen',
          biografie: [
            '32 Jahre alt',
            'KFZ-Meister'
          ],
          themen: [
            'Bildungsgerechtigkeit',
            'Sport'
          ],
          zitat: '',
          picture: require('@/assets/images/Kandidaten/Marko.webp')
        }, {
          vorname: 'Nikita',
          nachname: 'Soldatov',
          wahlkreis: 'Mittelsachsen 3',
          landkreis: 'Mittelsachsen',
          biografie: [

          ],
          themen: [

          ],
          zitat: '',
          picture: false
        }, {
          vorname: 'Robin',
          nachname: 'Schaluschke',
          wahlkreis: 'Leipzig 3',
          landkreis: 'Leipzig',
          biografie: [
            'Jahrgang 1997',
            'Seit 2017 Steuerfachangestellter',
            '2020 Umzug nach Leipzig'
          ],
          themen: [
            'Grunderwerbsteuerabschaffung',
            'Effiziente Verwaltung',
            'Generationengerechte Haushaltspolitik'
          ],
          zitat: 'Dem Fachkräftemangel muss auch damit begegnet werden, vorhandene Potenziale zu nutzen und Empfänger von Sozialleistungen in gebrauchte Arbeitskräfte umzuwandeln.',
          some: {
            instagram: 'robin_schaluschke'
          },
          picture: require('@/assets/images/Kandidaten/Robin.webp')
        }, {
          vorname: 'Shehzad',
          nachname: 'Shaikh',
          wahlkreis: 'Leipzig 4',
          landkreis: 'Leipzig',
          biografie: [
            'Wohnt in Deutschland seit 2012',
            'Kommt ursprünglich aus Pakistan',
            'Bachelor und Master im Fach Politikwissenschaft',
            'Arbeitet als Referent für die Freibeuter Fraktion im Leipziger Stadtrat'
          ],
          themen: [
            '<b>Silicon "Saxon" Valley 2.0</b> Sachsen zu einer europaweiten Forschungs- und Innovationsdrehscheibe machen.',
            '<b>Fachkräftemangel bekämpfen</b> Gewinnung qualifizierter Arbeitskräfte durch Steigerung der Lebensqualität in Sachsen, d.h. verbesserte Digitalisierung, Abbau von Bürokratie und Anreize zur Aufnahme einer Ausbildung oder Studiums anbieten.',
            '<b>Investitionen in Mobilität</b> Verbesserung des Mobilitätsangebots nicht nur innerhalb Sachsens, sondern auch von Sachsen zu den benachbarten europäischen Metropolen wie Prag und Breslau.'
          ],
          zitat: 'Deine Zukunft zählt, nicht deine Herkunft.',
          some: {
            linkedin: 'shehzad-shaikh-8b219215b'
          },
          picture: require('@/assets/images/Kandidaten/Shehzad.webp')
        }, {
          vorname: 'Daniel',
          nachname: 'Eich',
          wahlkreis: 'Leipzig 8',
          landkreis: 'Leipzig',
          biografie: [
            'von der Hauptschule zum Bachelor',
            'BA. Sport und Politikwissenschaft',
            'Sportler aus Leidenschaft'
          ],
          themen: [
            'Sportpolitik'
          ],
          some: {
            instagram: '_danieleich_'
          },
          zitat: 'Der Sport lebt vom Leistungsprinzip. Wer leistet, muss auch die Anerkennung dafür genießen. Dafür setze ich mich mit Leidenschaft ein.',
          picture: require('@/assets/images/Kandidaten/Daniel.webp')
        }, {
          vorname: 'Laurenz',
          nachname: 'Frenzel',
          wahlkreis: 'Nordsachsen 1',
          landkreis: 'Nordsachsen',
          biografie: [
            '2024 Abitur',
            'seit 2022 Mitglied bei der JuliA',
            '2022 4. Bundessieger Jugend debattiert'
          ],
          themen: [
            '<b>Bildung</b> Unterricht mit Inhalten, die Schüler individuell beschäftigen!',
            '<b>Verkehr</b> für eine Freiheit der Mobilitätswahl, egal ob Land oder Stadt!'
          ],
          zitat: 'Als Schüler weiß ich, vor welchen Problemen Schülerinnen und Schüler in Sachsen stehen, z. B. der Unterrichtsausfall, langweilige und unkreative Unterrichtsgestaltung oder Inhalte, die aus der Zeit gefallen sind, um einige Beispiele zu nennen. Gleichzeitig bin ich ohne Führerschein auf den ÖPNV angewiesen, so wie viele junge Menschen. Deshalb möchte ich, dass jeder eine wirkliche Wahl bei den Verkehrsmitteln hat, gerade in ländlichen Regionen. Ich kandidiere nicht, obwohl ich jung bin, sondern gerade weil!',
          some: {
            instagram: 'laurenz_frenzel',
            linkedin: 'laurenz-frenzel-a05824221'
          },
          picture: require('@/assets/images/Kandidaten/Laurenz.webp')
        }, {
          vorname: 'Max',
          nachname: 'Winkler',
          wahlkreis: 'Nordsachsen 3',
          landkreis: 'Nordsachsen',
          biografie: [
            'Aufgewachsen in Leipzig',
            'Master Informatik, arbeitet als Softwareentwickler'
          ],
          themen: [
            '<b>Bildung</b> Mehr Freiheiten an den Schulen, wer Lesen Schreiben und Rechnen kann, kann auch später im Selbststudium aufblühen',
            '<b>Wirtschaft</b> niemand kennt die Technologie der Zukunft, aber egal welche es wird, sie wird günstige und zuverlässige Energie brauchen',
            '<b>Demokratie</b> Demokratie geht vom Volke aus, dafür braucht es keine von oben finanzierten Vereine'
          ],
          zitat: 'Die Idee der Demokratie war die Überwachung der Regierenden durch das Volk. Gewählt zu werden kommt nicht mit einem Freifahrtsschein sich in alles mögliche klein klein der Bürger einzumischen. Denn das Private bleibt privat.',
          picture: require('@/assets/images/Kandidaten/Max.webp')
        }, {
          vorname: 'Carl',
          nachname: 'Gruner',
          wahlkreis: 'Dresden 2',
          landkreis: 'Dresden',
          biografie: [
            '23 Jahre alt',
            'aufgewachsen in Radeberg, lebt in Dresden',
            'hat Politikwissenschaft studiert',
            'Reservist bei den Heimatschutzkräften der Bundeswehr'
          ],
          themen: [
            'wehrhafte Demokratie wider Extremismus',
            'Unterrichtsausfall beenden',
            'ein Strukturwandel, der Wirtschafswachstum und Nachhaltigkeit vereint',
            'eine digitale, unkomplizierte Verwaltung'
          ],
          zitat: 'Sachsen braucht eine starke, wehrhafte Demokratie. Wenn Populismus um sich greift, braucht es aufrechte Demokratinnen und Demokraten im Parlament, die sich dem entgegenstellen. Ich möchte nicht, dass Sachsen länger Spielwiese für Extremisten ist. Kräftige, vernünftige Stimmen wider Polemisierung – deshalb kandidiere ich für den Landtag.',
          some: {
            instagram: 'carl_gruner',
            threads: 'carl_gruner',
            twitter: 'carl_gruner',
            linkedin: 'carl-gruner-1601322b8/'
          },
          picture: require('@/assets/images/Kandidaten/Carl.webp')
        }, {
          vorname: 'Dominik',
          nachname: 'Zoch',
          wahlkreis: 'Dresden 7',
          landkreis: 'Dresden',
          biografie: [
            'Geboren 1993 in Dresden und aufgewachsen in Coswig',
            'Studium in Meißen „Allgemeine Verwaltung“',
            'Seit 2015 tätig bei einem großen Träger von Kindertageseinrichtungen, aktuell als Personalplaner und -controller',
            'Seit 2018 Mitglied bei der JuliA und FDP'
          ],
          themen: [
            '<b>Kindertagesbetreuung</b> Angleichung des Betreuungsschlüssels an den bundesdeutschen Durchschnitt',
            '<b>schulische Bildung</b> Anstoßen einer grundlegenden Bildungsreform im Freistaat',
            '<b>Verwaltung</b> sachsenweit effektiv und effizient gestalten'
          ],
          zitat: 'Für die Menschen, die eine rosige Zukunft gestalten wollen, schaffen wir die Rahmenbedingungen: Gute Startchancen durch beste Bildung sowie einen Staat, der ermöglicht und beschleunigt.',
          some: {
            instagram: 'Dominik_Dresden',
            twitter: 'Dominik_Dresden',
            threads: 'Dominik_Dresden'
          },
          picture: require('@/assets/images/Kandidaten/Dominik.webp')
        }, {
          vorname: 'Nora',
          nachname: 'Hohlfeld',
          wahlkreis: 'SOE 4',
          landkreis: 'SOE',
          biografie: [
            'seit 2019 Trainerin in einem Sportverein',
            '2020 Abi am Sportgymnasium in Dresden',
            '2023 Abschluss der Ausbildung zur Rechtsanwaltsfachangestellten'
          ],
          themen: [
            '<b>Sport</b> und Sportförderung in Sachsen, die Renovierung und der Bau neuer Sportstätten, Sportgroßveranstaltungen in Sachsen, Spitzen- und Breitensportförderung',
            '<b>Justiz</b> die schnell, agil, funktionsfähig und Digital ist',
            '<b>Sicherheit</b> für alle Menschen im Freistaat in allen Lebenslagen'
          ],
          zitat: 'Ich werde mich für den Sport und die Sportförderung in Sachsen einsetzen. Durch die Renovierung und den Bau neuer Sportstätten sowie die Durchführung von Sportgroßveranstaltungen möchte ich den Spitzen- und Breitensport in Sachsen unterstützen. Außerdem möchte ich damit ein Zeichen für Sachsen als Sportland setzen.',
          some: {
            instagram: 'nora.hohlfeld',
            facebook: 'nora.hohlfeld.3',
            twitter: 'hohlfeld_nora'
          },
          picture: require('@/assets/images/Kandidaten/Nora.webp')
        }, {
          vorname: 'Eddie',
          nachname: 'Friedrich',
          wahlkreis: 'Bautzen 4',
          landkreis: 'Bautzen',
          biografie: [
            'Geboren 2005 in Hoyerswerda und aufgewachsen in Wittichenau',
            'Studium in Leipzig "Rechtswissenschaften"',
            'Seit 2021 Mitglied bei der JuliA und FDP'
          ],
          themen: [
            'Weltbeste Bildung',
            'Erfolgreicher Strukturwandel',
            'Ehrenamt stärken'
          ],
          zitat: 'Politisches Handeln muss sich mehr an den Problemen der Menschen vor Ort und der Lebenspraxis orientieren.',
          some: {
            instagram: 'eddie_fdr',
            linkedin: 'eddie-friedrich-0120aa248',
            facebook: 'profile.php?id=100084257293017'
          },
          picture: require('@/assets/images/Kandidaten/Eddie.webp')
        }, {
          vorname: 'Moritz',
          nachname: 'Weber',
          wahlkreis: 'Görlitz 2',
          landkreis: 'Görlitz',
          biografie: [
            '2000 geboren in Karlsruhe (Kind von West und Ost)',
            'Aufgewachsen in Dresden',
            'Studierte in Mittweida'
          ],
          themen: [
            '<b>Tourismus</b> Ganz Sachsen ist eine Sehenswürdigkeit. Tourismusmarketing auch in unerschlossenen Regionen fördern.',
            '<b>Sachsen ist Europa</b> Die Kooperationen in den Euroregionen ausbauen.',
            '<b>Verkehr/Infrastruktur</b> In ganz Sachsen schnell unterwegs, auf der Schiene, auf dem Rad und im Netz.'
          ],
          zitat: 'Der Ausbau der sächsischen Infrastruktur dauert zu lange. Eine Bahnstrecke darf nicht nach zwei Jahrzehnten immer noch nicht fertig sein. Bauen wir unsere Verbindungen nach Europa aus, anstatt unsere Prüfverfahren!',
          picture: require('@/assets/images/Kandidaten/Moritz.webp')
        }, {
          vorname: 'Michel',
          nachname: 'Kretschmer',
          wahlkreis: 'Görlitz 4',
          landkreis: 'Görlitz',
          biografie: [
            'Geboren am 27.06.1989 in Zittau',
            'Familienstand: ledig',
            'Berufsausbildung: Verwaltungsfachangestellter für Landes- und Kommunalverwaltung und geprüfter Wirtschaftsfachwirt',
            'Angestellter im öffentlichen Dienst (Landkreis Görlitz)'
          ],
          themen: [
            'Digitalisierung der Wirtschaft und Verwaltung',
            'kommunale Selbstverwaltung wiederherstellen (-> Sächsisches Finanzausgleichsgesetz)',
            'Ausbau und Erneuerung der Infrastruktur',
            'Reformierung des Bildungssystems inkl. Demokratieförderung ab dem Kindesalter'
          ],
          zitat: '',
          some: {
            instagram: 'michel.kretschmer',
            facebook: 'michel.kretschmer'
          },
          picture: require('@/assets/images/Kandidaten/Michel.webp')
        }
      ]
    }
  }
}
